<script>

import { reactive, ref } from 'vue'
import { userService } from '../../../helpers/authservice/user.service'
import router from '../../../router'
import Swal from "sweetalert2"
import { useRoute } from 'vue-router'
import { toNumber } from '@vue/shared';
import { ApiService } from '../../../helpers/api.service'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  setup() {
    const route = useRoute()
    const { message, redirectFrom } = route.query
    const query = route.query
    const stage = ref(query.stage ? parseInt(query.stage) : 1)
    const qrCodeUri = ref(null)
    const showQrCodeModal = ref(false)
    const showOtpRegLink = ref(null)
    const passwordValidator = ref(false)
    const confirmPasswordAddon = ref(false)
    const digits = ref('')
    const verificationCodeError = ref(null)

    const form = reactive({
      email: query.email ? query.email : '',
      senha: '',
      sistema: query.sistema ? query.sistema : '281486ff-c347-cd3c-7e43-1756d13ebf4d',
      // rememberme: localStorage.getItem('rememberme') === 'true' ? true : false,
    })
    const firstAcessForm = reactive({
      email: query.email ? query.email : '',
      senha_confirmacao: '',
      senha: '',
    })
    const authCode = ref([]);

    const loading = ref(false)

    const rememberedUser = reactive({
      show: false,
      name: '',
      avatar: '',
      token: '',
    });

    if (localStorage.getItem('rememberedUser')) {
      const localRU = JSON.parse(localStorage.getItem('rememberedUser'));
      rememberedUser.show = true;
      rememberedUser.name = localRU.name;
      rememberedUser.token = localRU.token;
      rememberedUser.avatar = localRU.avatar ? 'data:image/png;base64, ' + localRU.avatar : false;
    }

    /**
     * get form data and send to api
     */
    async function handleLoginUser() {
      if (loading.value) return false;

      loading.value = true
      firstAcessForm.email = form.email
      const error = await userService.login({ ...form, otp: '' }, redirectFrom)
      
      if (error == 'TFA_DISABLED') {
        stage.value = 2
        generateOtp()
      } else if (error == 'OTP_NOT_INFORMED') {
        stage.value = 3
        setTimeout(() => {
          document.getElementById('digit1-input').focus()
        }, 400)
      } else if (error == 'FIRST_ACCESS') {
        firstAcessForm.email = form.email
        firstAcessForm.senha = form.senha
        stage.value = 4
      } else if (!error.token) {
        Swal.fire({
          icon: "error",
          text: error,
          html: error,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
      loading.value = false
    }

    async function handleFirstAcessPasswordSubmit() {
      if (loading.value) {
        return false;
      }
      if (firstAcessForm.senha !== firstAcessForm.senha_confirmacao) {
        document.getElementById('confirmPassword').setCustomValidity("Senhas não correspondem!");
        return false;
      }

      loading.value = true
      const successOrError = await userService.firstAcessPassword({ ...firstAcessForm })
      if (!successOrError.status || successOrError.status !== 201) {
        Swal.fire({
          icon: "error",
          text: successOrError,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      } else {
        stage.value = 1
        Swal.fire({
          icon: "success",
          text: successOrError.data.mensagem + '.',
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
      loading.value = false
    }

    async function handleAuthCode() {
      if (loading.value) {
        return false;
      }

      loading.value = true
      const error = await userService.login({ ...form, otp: authCode.value.join('') }, redirectFrom)

      if (!error.token) {
        Swal.fire({
          icon: "error",
          text: error,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
      loading.value = false
    }

    async function validateVerificationCode() {
      try {
        loading.value = true
        verificationCodeError.value = null

        const data = await userService.validateVerificationCode({ ...form, otp: digits.value })

        if (!data?.token) {
          verificationCodeError.value = data
          return
        }

        digits.value = ''
        qrCodeUri.value = null
        stage.value = 3
      } catch (error) {
        verificationCodeError.value = error
      } finally {
        loading.value = false
      }
    }

    const pasteAuthCode = (e) => {
      const value = e.clipboardData.getData('Text');
      if (value.length == 6) {
        authCode.value = value.split('');
        handleAuthCode()
      }
    }

    /**
     * Automatic change input focus at confirmation code
     */
    const moveToNext = (e) => {
      const index = e.target.dataset.index;
      if (e.key == 'Backspace' || e.code == 'Backspace') {
        if (index > 1) {
          document.getElementById(`digit${toNumber(index) - 1}-input`).focus();
        }
      }
      if (e.target.value == '' || e.target.value == ' ' || !/[0-9]/.test(e.target.value)) {
        e.target.value = '';
        return false;
      }
      if (index == 6 && (
        authCode.value[0] !== '' &&
        authCode.value[1] !== '' &&
        authCode.value[2] !== '' &&
        authCode.value[3] !== '' &&
        authCode.value[4] !== '' &&
        authCode.value[5] !== '')) {
        handleAuthCode()
      } else {
        if (index < 6) {
          document.getElementById(`digit${toNumber(index) + 1}-input`).focus();
        }
      }
    }

    async function generateOtp() {
      await ApiService.post('/generate-otp', { email: form.email, senha: form.senha })
        .then(response => {
          qrCodeUri.value = response.data.uri
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.mensagem,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          })
          stage.value = 1
        });
    }

    function removeRemembered() {
      rememberedUser.show = false;
      localStorage.clear();
    }

    function continueAsRememberedUser() {
      localStorage.setItem('user', JSON.stringify({ token: rememberedUser.token }));
      router.replace({ name: 'default' });
    }

    function setConfirmPasswordAddon() {
      if (confirmPasswordAddon.value) {
        return false;
      }
      confirmPasswordAddon.value = true;
      const passowrdAddon = document.querySelector(".confirm-password-addon")
      const passwordInput = document.querySelector(".confirm-password-input")
      passowrdAddon?.addEventListener("click", function () {
        if (passwordInput.type === "password") {
          passwordInput.type = "text"
        } else {
          passwordInput.type = "password"
        }
      })

      passwordInput.onchange = function () {
        if (firstAcessForm.senha === firstAcessForm.senha_confirmacao) {
          document.getElementById('confirmPassword').setCustomValidity("");
        }
      }
    }

    function setPasswordValidator() {
      if (passwordValidator.value) {
        return false;
      }
      passwordValidator.value = true;
      const passowrdAddon = document.querySelector(".password-addon")
      passowrdAddon?.addEventListener("click", function () {
        const passwordInput = document.querySelector(".password-input")
        if (passwordInput.type === "password") {
          passwordInput.type = "text"
        } else {
          passwordInput.type = "password"
        }
      })

      var password = document.getElementById("password-input")
      password.onchange = this.validatePassword
      // password operations
      var myInput = document.getElementById("password-input")
      var letter = document.getElementById("pass-lower")
      var capital = document.getElementById("pass-upper")
      var number = document.getElementById("pass-number")
      var length = document.getElementById("pass-length")

      // When the user clicks on the password field, show the message box
      myInput.onfocus = function () {
        document.getElementById("password-contain").style.display = "block"
      }

      // When the user clicks outside of the password field, hide the password-contain box
      myInput.onblur = function () {
        document.getElementById("password-contain").style.display = "none"
      }

      // When the user starts to type something inside the password field
      myInput.onkeyup = function () {
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g
        if (myInput.value.match(lowerCaseLetters)) {
          letter.classList.remove("invalid")
          letter.classList.add("valid")
        } else {
          letter.classList.remove("valid")
          letter.classList.add("invalid")
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g
        if (myInput.value.match(upperCaseLetters)) {
          capital.classList.remove("invalid")
          capital.classList.add("valid")
        } else {
          capital.classList.remove("valid")
          capital.classList.add("invalid")
        }

        // Validate numbers
        var numbers = /[0-9]/g
        if (myInput.value.match(numbers)) {
          number.classList.remove("invalid")
          number.classList.add("valid")
        } else {
          number.classList.remove("valid")
          number.classList.add("invalid")
        }

        // Validate length
        if (myInput.value.length >= 8) {
          length.classList.remove("invalid")
          length.classList.add("valid")
        } else {
          length.classList.remove("valid")
          length.classList.add("invalid")
        }
      }
    }

    return {
      setConfirmPasswordAddon,
      setPasswordValidator,
      handleFirstAcessPasswordSubmit,
      firstAcessForm,
      showOtpRegLink,
      message,
      continueAsRememberedUser,
      removeRemembered,
      rememberedUser,
      form,
      handleLoginUser,
      loading,
      stage,
      authCode,
      moveToNext,
      generateOtp,
      qrCodeUri,
      showQrCodeModal,
      pasteAuthCode,
      validateVerificationCode,
      digits,
      verificationCodeError
    }
  },
  mounted() {
    const passowrdAddon1 = document.querySelector(".password-addon1")
    passowrdAddon1?.addEventListener("click", function () {
      const passwordInput1 = document.querySelector(".password-input1")
      if (passwordInput1.type === "password") {
        passwordInput1.type = "text"
      } else {
        passwordInput1.type = "password"
      }
    })
  }
}
</script>

<template>
  <div class="auth-page-wrapper pt-1">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logos/ApoioBranco.png" alt="" height="100">
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-4 d-flex justify-content-center align-items-center" v-if="rememberedUser.show">
            <div class="my-auto px-2 text-center">
              <div @click="continueAsRememberedUser" class="btn bg-light py-2 px-4 rounded shadow-sm">
                <div class="mx-auto" style="width: 150px; height: 150px; overflow: hidden; border-radius: 50%;">
                  <img v-if="rememberedUser.avatar" :src="rememberedUser.avatar" alt="User avatar"
                    style="width: 100%; height: 100%; object-fit: cover;" />
                  <i v-if="!rememberedUser.avatar" class="ri-user-3-line" style="font-size: 130px;"></i>
                </div>

                <h6 class="mt-3">
                  Continuar como <span class="text-primary">{{ rememberedUser.name }}</span>
                </h6>
              </div>

              <a @click="removeRemembered" href="#" class="mt-3 text-danger my-auto d-block">
                Remover <i class="ri-delete-back-2-fill align-bottom"></i>
              </a>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-center" v-if="message">
            <b-alert show variant="danger" dismissible>
              {{ message }}
            </b-alert>
          </div>
          <div v-if="stage == 1" :class="(!rememberedUser.show ? 'mx-auto ' : '') + 'col-md-8 col-lg-6 col-xl-5'">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Bem-vindo de volta!</h5>
                </div>

                <div class="px-2 mt-4 d-flex flex-column">
                  <label>Tipo de acesso</label>
                  <select v-model="form.sistema" class="form-select">
                    <option selected value="281486ff-c347-cd3c-7e43-1756d13ebf4d">Colaborador</option>
                    <option value="f3d11f0c-352d-43c9-ba53-a4a2149df719">Fornecedor</option>
                    <option value="3b416297-880d-4ed5-8ca0-0a3020681057">Cliente</option>
                  </select>
                  <!-- <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" checked class="btn-check" name="sistema" value="281486ff-c347-cd3c-7e43-1756d13ebf4d" v-model="form.sistema" id="colaborador-check">
                    <label class="btn btn-outline-primary" for="colaborador-check">Colaborador</label>
                    <input type="radio" class="btn-check" name="sistema" value="f3d11f0c-352d-43c9-ba53-a4a2149df719" v-model="form.sistema" id="fornecedor-check">
                    <label class="btn btn-outline-primary" for="fornecedor-check">Fornecedor</label>
                    <input type="radio" class="btn-check" name="sistema" value="3b416297-880d-4ed5-8ca0-0a3020681057" v-model="form.sistema" id="cliente-check">
                    <label class="btn btn-outline-primary" for="cliente-check">Cliente</label>
                    <input type="radio" class="btn-check" name="sistema" value="0ebbb123-79bf-4367-9779-f476086f6c58" v-model="form.sistema" id="task-check">
                    <label class="btn btn-outline-primary" for="task-check">Chamado</label>
                  </div> -->
                </div>

                <div class="p-2 mt-1">
                  <form @submit.prevent="handleLoginUser">

                    <div class="mb-3">
                      <label for="username" class="form-label">
                        Email ou Conta
                      </label>
                      <input v-model="form.email" type="text" class="form-control" id="username"
                        placeholder="Coloque seu Email ou sua Conta">
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="password-input1">
                        Senha
                      </label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input v-model="form.senha" type="password" class="form-control pe-5 password-input1"
                          placeholder="Coloque sua senha" id="password-input1">
                        <button
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon1"
                          type="button" id="password-addon1">
                          <i class="ri-eye-fill align-middle" />
                        </button>
                      </div>
                    </div>

                    <div v-if="loading" class="mt-3 d-flex justify-content-center">
                      <b-spinner variant="success"></b-spinner>
                    </div>
                    <div v-if="!loading" class="mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Entrar
                      </button>
                    </div>


                    <div class="mt-4 text-center">
                      <router-link to="/auth/reset-pwd-basic" class="text-muted">
                        Esqueceu a senha?
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Não tem uma conta?
                <router-link to="/register" class="fw-semibold text-primary text-decoration-underline">
                  Registrar
                </router-link>
              </p>
            </div>

          </div>
          <div v-else-if="stage == 2" :class="(!rememberedUser.show ? 'mx-auto ' : '') + 'col-md-8 col-lg-6 col-xl-5'">
            <div class="card mt-4">
              <button @click="stage = 1, qrCodeUri = null" class="btn-close ms-auto mt-3 me-3" type="button"
                aria-label="Close"></button>
              <div class="card-body p-4 pt-0">
                <div class="">
                  <p>
                    Conecte um aplicativo autenticador (Google Authenticator) que irá gerar os códigos de verificação.
                    <br>
                    Você pode usar os códigos quando precisarmos verificar sua identidade.
                  </p>
                  <ol>
                    <li>Baixe e instale o aplicativo identificador em um dispositivo móvel.</li>
                    <li>Use o aplicativo para ler esse código QR.</li>
                    <li>Insira o código gerado pelo aplicativo.</li>
                  </ol>
                </div>
                <div class="mb-2 d-flex justify-content-center">
                  <b-spinner v-if="!qrCodeUri" variant="success" />
                  <div v-if="qrCodeUri" class="d-flex flex-column">
                    <qrcode-vue renderAs="svg" :value="qrCodeUri" :size="250" :margin="1">
                    </qrcode-vue>
                  </div>
                </div>
                <div class="p-2" v-if="qrCodeUri">
                  <div>
                    <b-alert v-model="verificationCodeError" variant="danger" class="mt-3 mb-3" dismissible>{{
            verificationCodeError }}</b-alert>

                    <div class="pb-1">
                      <span>Código de verificação</span>
                    </div>
                    <input id="code-verification" class="form-control pt-2" placeholder="Ex.: 827391" v-model="digits"
                      required>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <button @click="stage = 1, qrCodeUri = null" class="btn btn-light mx-auto mt-4"
                        type="button">Cancelar</button>
                    </div>
                    <div>
                      <button type="button" class="btn btn-success mx-auto mt-4" :disabled="loading"
                        @click="validateVerificationCode">Validar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Não tem uma conta?
                <router-link to="/register" class="fw-semibold text-primary text-decoration-underline">
                  Registrar
                </router-link>
              </p>
            </div>

          </div>
          <div v-else-if="stage == 3" :class="(!rememberedUser.show ? 'mx-auto ' : '') + 'col-md-8 col-lg-6 col-xl-5'">
            <div class="card mt-4">

              <button @click="stage = 1, qrCodeUri = null" class="btn-close ms-auto mt-3 me-3" type="button"
                aria-label="Close"></button>

              <div class="card-body p-4 pt-0">
                <div class="p-2">
                  <div class="mb-4">
                    <div class="avatar-lg mx-auto">
                      <div class="avatar-title bg-light text-primary display-5 rounded-circle">
                        <i class="ri-lock-password-fill"></i>
                      </div>
                    </div>
                  </div>

                  <div class="text-muted text-center mb-4 mx-lg-3">
                    <p>Insira seu código de verificação do Google Authenticator</p>
                  </div>

                  <form @submit.prevent="handleAuthCode">
                    <div v-if="loading" class="mt-3 d-flex justify-content-center">
                      <b-spinner variant="success"></b-spinner>
                    </div>
                    <div v-else class="d-flex gap-2">
                      <input type="text" v-for="index in 6" :key="index" v-model="authCode[index - 1]"
                        :disabled="loading" :data-index="index" @keyup="moveToNext" @paste="pasteAuthCode" maxLength="1"
                        pattern="\d*" :id="`digit${index}-input`"
                        :class="(index === 3 ? 'me-3' : '') + ' form-control form-control-lg bg-light border-light text-center text-primary fw-bold'">
                    </div>
                  </form>

                  <div class="row" v-if="showOtpRegLink">
                    <button @click="generateOtp" class="btn btn-ghost text-primary mt-4">
                      Ainda não tenho um código
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Não tem uma conta?
                <router-link to="/register" class="fw-semibold text-primary text-decoration-underline">
                  Registrar
                </router-link>
              </p>
            </div>
          </div>

          <div v-else-if="stage == 4" :class="(!rememberedUser.show ? 'mx-auto ' : '') + 'col-md-8 col-lg-6 col-xl-5'">
            <div class="card mt-4">
              <button @click="stage = 1, qrCodeUri = null" class="btn-close ms-auto mt-3 me-3" type="button"
                aria-label="Close"></button>
              <div class="card-body p-4 pt-0">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Primeiro acesso!
                  </h5>
                  <div class="text-muted text-center mb-4 mx-lg-3">
                    <p>Escolha uma nova senha para fazer login no sistema.</p>
                  </div>
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="handleFirstAcessPasswordSubmit" class="needs-validation">
                    <div class="mb-3">
                      <label class="form-label" for="password-input">
                        Senha
                        <span class="text-danger">*</span>
                      </label>
                      <div class="position-relative auth-pass-inputgroup">
                        <input v-model="firstAcessForm.senha" @input="setPasswordValidator" type="password"
                          class="form-control pe-5 password-input" onpaste="return false" placeholder="Sua nova senha"
                          id="password-input" aria-describedby="passwordInput"
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required>
                        <button
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle" />
                        </button>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="confirmPassword" class="form-label">
                        Confirmar Senha
                        <span class="text-danger">*</span>
                      </label>
                      <div class="position-relative auth-pass-inputgroup">
                        <input v-model="firstAcessForm.senha_confirmacao" @input="setConfirmPasswordAddon"
                          type="password" onpaste="return false" class="form-control confirm-password-input"
                          id="confirmPassword" placeholder="Repita sua nova senha" required>
                        <button
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted confirm-password-addon"
                          type="button" id="confirm-password-addon">
                          <i class="ri-eye-fill align-middle" />
                        </button>
                      </div>
                    </div>
                    <div id="password-contain" class="p-3 bg-light mb-2 rounded">
                      <h5 class="fs-13">Senha tem que conter:</h5>
                      <p id="pass-length" class="invalid fs-12 mb-2">Mínimo <b>8 caracteres</b></p>
                      <p id="pass-lower" class="invalid fs-12 mb-2">Letra <b>minúscula</b> (a-z)</p>
                      <p id="pass-upper" class="invalid fs-12 mb-2">Letra <b>maiúscula</b> (A-Z)</p>
                      <p id="pass-number" class="invalid fs-12 mb-0"><b>Número</b> (0-9) </p>
                    </div>
                    <div class="mt-4">
                      <div v-if="loading" class="d-flex justify-content-center">
                        <b-spinner variant="success"></b-spinner>
                      </div>
                      <button v-if="!loading" class="btn btn-success w-100" type="submit">
                        Continuar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-4 text-center">
              <p class="mb-0">
                Não tem uma conta?
                <router-link to="/register" class="fw-semibold text-primary text-decoration-underline">
                  Registrar
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                {{ new Date().getFullYear()}} © Apoio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>